.formCom .formCell {
  display: flex;
  flex-wrap: wrap;
}
.formCom .formCell .el-form-item {
  width: 50%;
}
.formCom .chapterl-btns .el-button {
  padding: 8px;
}
.formCom .el-cascader {
  width: 100%;
}
.formCom .el-progress {
  width: 200px;
}
.formCom .chapterl-trees {
  padding: 15px 15px 15px 0;
}
.formCom .chapterl-trees .el-tree-node__content {
  height: 36px;
}
.formCom .chapterl-trees .draggable-item {
  margin-left: 5px;
}
.formCom .chapterl-trees .draggable-item em {
  margin-right: 5px;
}
.formCom .chapterl-trees .trees-btns {
  margin-left: 15px;
}
.formCom .chapterl-trees .trees-btns .showbtn {
  color: #fff;
}
.formCom .chapterl-trees .trees-btns > em,
.formCom .chapterl-trees .trees-btns .handle > em {
  color: #4089fa;
  font-size: 14px;
  cursor: pointer;
}
.formCom .chapterl-trees .trees-btns > em:not(:last-child),
.formCom .chapterl-trees .trees-btns .handle > em:not(:last-child) {
  margin-right: 5px;
}
.formCom .chapteru-lists {
  padding: 15px 15px 15px 0;
}
.formCom .chapteru-lists .el-divider--horizontal {
  margin: 5px;
}
.formCom .chapteru-lists .cl-subhint {
  display: flex;
}
.formCom .chapteru-lists .cl-subhint span {
  font-size: 14px;
  color: #666;
}
.formCom .chapteru-lists .cl-subhint span:nth-child(2) {
  margin-left: 15px;
}
.formCom .chapteru-lists .cl-subhint em {
  margin: 0 5px;
}
.formCom .chapteru-lists .cl-btns i {
  cursor: pointer;
}
.formCom .chapteru-lists .cl-btns i:not(:last-child) {
  margin-right: 5px;
}
.formCom .cl-img-box .el-icon-receiving {
  font-size: 40px;
  color: #999;
  margin-right: 15px;
}
.formCom .pageForm-btns {
  padding: 15px 0;
  text-align: center;
}
.formCom .pageForm-btns .el-button span {
  min-width: 4em;
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.originalParameter .el-form-item__label {
  color: red;
}
.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
}
.btn-box button {
  padding: 12px 30px;
}
.btn-box .el-button.is-disabled,
.btn-box .el-button.is-disabled:focus,
.btn-box .el-button.is-disabled:hover {
  background: #a9a9a9;
  border-color: #a9a9a9;
  color: #fff;
}
 /deep/ .el-table .warning-row {
  color: red;
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
