








































































































































































































































































































































































.el-dialog--center .el-dialog__body {
    padding: 10px 25px 0 !important;
}
.CourseTableBox2 {
    .CourseTable {
    width:30%;
}
.paperMation {
    width:69%;
    .paperMationDetail {
        padding:0;
    }
}
}

